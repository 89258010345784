<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" showOutput :showSearch="true" @getList="getList"
			@clearSearch="clearSearch">
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.phone" size="small" clearable style="width: 140px" placeholder="按手机号"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.vip" size="small" clearable style="width: 100px" placeholder="用户属性">
					<el-option label="VIP" value="1"></el-option>
					<el-option label="非VIP" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.city" size="small" clearable style="width: 100px" placeholder="来源">
					<el-option label="苏州" value="20"></el-option>
					<el-option label="无锡" value="30"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-date-picker v-model="searchFormData.ctime" clearable type="datetime" format="yyyy-MM-dd"
					value-format="yyyy-MM-dd" placeholder="抽奖时间" size="small">
				</el-date-picker>
			</el-form-item>

			<!--表格内容-->
			<vxe-table-column slot="table-item" field="phone" title="用户信息" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" field="city" title="来源" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="is_vip" title="用户属性" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="lottery_text" title="奖品" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="tm" title="抽奖时间" align="center" min-width="80px" />
		</Tables>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	export default {
		name: 'market-activitycommon-log',
		components: {
			Tables
		},
		data() {
			return {
				// 表格信息
				tableName: '活动用户',
				isLoading: false,
				tableData: [],
				totalPage: 0,
				searchFormData: {
				},
				// 详情
				detailDialogShow: false,
				detailDialogFormData: {},
			}
		},
		methods: {
			// 表格列表
			async getList(currentPage, pageSize) {
				let params = {
					token: this.$store.state.user.token,
					activity_id: this.$route.query.id,
					page: currentPage,
					size: pageSize
				}
				params = Object.assign(params, this.searchFormData);
				this.isLoading = true
				const res = await this.$api.Market.ActivityCommon.log(params)
				this.tableData = res.data;
				for(let v of this.tableData){
					v.lottery_text = '';
					for(let r of v.ext){
						if(r.price){
							v.lottery_text += '获得' + r.num + "*" + r.name + "(" + r.price + ")\n";
						}else{
							v.lottery_text += '获得' + r.num + "*" + r.text + "\n";
						}
					}
					
				}
				this.totalPage = res.total;
				this.isLoading = false
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {}
			},
			// 关闭弹窗
			closeDialog() {
				this.detailDialogShow = false
				this.refundDialogShow = false
			},
		}
	}
</script>

<style lang="scss" scoped>
	.row-title {
		font-size: 14px;
		line-height: 20px;
		padding: 4px 0;
		margin-bottom: 18px;

		&:before {
			content: " ";
			width: 5px;
			height: 20px;
			background: #008e4d;
			display: block;
			float: left;
			margin: 0 10px 0 0;
		}
	}
</style>
